import React, { useState, useCallback, useContext, useMemo, useEffect } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import LandingPageLayout from "../../layouts/landing-page-layout/LandingPageLayout";
import Input from '../../components/input/Input';
import Validator from "../../services/validator/Validator";
import Authenticator from "../../services/Authenticator";
import UserContext from "../../components/user-context/UserContext";
import { NotificationContext } from '../../components/notification/Notification';
import { handleNavigate } from "../../services/Navigate";

import './ForgotPassword.scss';

const RESET_PASSWORD_FIELDS = [
    {
        placeholder: 'Email Address', 
        name: 'emailAddress', 
        type: 'email', 
        isRequired: true, 
        validation: (value) => Validator.checkValidEmail(value) 
    },
    {
        placeholder: 'New password', 
        name: 'newPassword', 
        type: 'password', 
        isRequired: true, 
        validation: (value) => Validator.checkValidPassword(value) 
    },
    {
        placeholder: 'Confirm New password', 
        name: 'confirmNewPassword', 
        type: 'password', 
        isRequired: true, 
        validation: (value, newPassword) => Validator.checkEqualPassword(value, newPassword) 
    },
];

const ForgotPassword = () => {
    const { notify } = useContext(NotificationContext);
    const userContext = useContext(UserContext);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        emailAddress: [],
        newPassword: [],
        confirmNewPassword: []
    });
    const [fieldData, setFieldData] = useState({
        emailAddress: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined
    });

    const [globalError, setGlobalError] = useState(null);

    const urlParams = typeof window !== 'undefined' && window?.location?.search ? new URLSearchParams(window?.location?.search) || undefined : undefined;
    const email = typeof urlParams !== 'undefined' ? urlParams?.get('email') : undefined;
    const code = typeof urlParams !== 'undefined' ? urlParams.get('code') : undefined;

    useEffect(() => {
        notify({
            message: globalError,
            type: 'critical'
        })
    }, [globalError]);

    const onChange = useCallback((evt) => {
        const value = evt.target.value;
        const currentInput = RESET_PASSWORD_FIELDS.filter((field) => field.name === evt.target.name)[0];
        const currentInputErrors = currentInput?.validation(value, fieldData.newPassword);
        
        if (currentInput?.name === "newPassword") {
            const newPasswordInputErrors = RESET_PASSWORD_FIELDS.filter((field) => field.name === "newPassword")[0]?.validation(value);
            const confirmPasswordInputErrors = RESET_PASSWORD_FIELDS.filter((field) => field.name === "confirmNewPassword")[0]?.validation(fieldData.confirmNewPassword, value);
            
            setFieldErrors((prevState) => ({ ...prevState, newPassword: newPasswordInputErrors, confirmNewPassword: confirmPasswordInputErrors }));
        } else if (currentInput?.name === "confirmNewPassword") {
            const newPasswordInputErrors = RESET_PASSWORD_FIELDS.filter((field) => field.name === "newPassword")[0]?.validation(fieldData.newPassword);
            const confirmPasswordInputErrors = RESET_PASSWORD_FIELDS.filter((field) => field.name === "confirmNewPassword")[0]?.validation(value, fieldData.newPassword);
            
            setFieldErrors((prevState) => ({ ...prevState, newPassword: newPasswordInputErrors, confirmNewPassword: confirmPasswordInputErrors }));
        } else {
            setFieldErrors((prevState) => ({ ...prevState, [evt.target.name]: currentInputErrors}));
        }
        
        setFieldData((prevState) => ({ ...prevState, [evt.target.name]: value }));
    }, [fieldData]);

    const isButtonDisabled = useMemo(() => {
        const isError = Object.entries(fieldErrors).filter((error) => error[1].length !== 0) && Object.entries(fieldErrors).filter((error) => error[1]?.length !== 0);
        if (!isError.length) {
            const {
                emailAddress,
                newPassword,
                confirmNewPassword
            } = fieldData;

            return !emailAddress && !(newPassword && confirmNewPassword) ? true : false;
        }

        return true;
    }, [fieldData, fieldErrors]);

    const resetPasswordDOM = useMemo(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let fieldsToMap = RESET_PASSWORD_FIELDS;
        if (!code || !email) {
            fieldsToMap = RESET_PASSWORD_FIELDS.slice(0, 1);
        }
        
        if (code && email) {
            fieldsToMap = RESET_PASSWORD_FIELDS.slice(1, 3);
        }

        return fieldsToMap.map((field, index) => {
            const autoFocus = index === 0 ? { autoFocus: true } : {};
            return <Form.Group key={field.name} className='mb-3'>
                <Input {...autoFocus} disabled={code && email && field.name === 'emailAddress'} value={fieldData[field.name]} field={field} onChange={onChange} errors={fieldErrors[field.name]} showErrorsOnFocusOnly={false}/>
            </Form.Group>;
        });
    }, [fieldData, fieldErrors, onChange, code, email]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fieldErrors.emailAddress.length === 0 && fieldData.newPassword === undefined && fieldData.confirmNewPassword === undefined) {
            (async () => {
                try {
                    await Authenticator.forgotPassword(fieldData.emailAddress);
                    setIsSubmitted(true);
                } catch (err) {
                    setGlobalError(err?.message);
                    return;
                }
            })();
        }

        if (fieldData.newPassword !== undefined && fieldData.confirmNewPassword !== undefined && fieldErrors.emailAddress.length === 0 && fieldErrors.newPassword.length === 0 && fieldErrors.confirmNewPassword.length === 0) {
            (async () => {
                try {
                    await Authenticator.forgotPasswordSubmit(code, email, fieldData.confirmNewPassword, fieldData.newPassword);
                    setIsSubmitted(true);
                } catch (err) {
                    setGlobalError(err?.message);
                    return;
                }
            })();
        }
    };

    const elementsDOM = useMemo(() => {
        if (userContext?.state === userContext?.STATES?.LOGGED_IN) {
            handleNavigate('/account/settings');
            return;
        }

        const formDOM = !isSubmitted ? <Form className='w-100 formWrapper'>
            {resetPasswordDOM}
            <Button variant="primary" type="submit" className='mt-4' disabled={isButtonDisabled} onClick={handleSubmit} style={{padding: '1rem 2rem'}}>
                {code && email ? 'Update Password' : 'Reset Password'}
            </Button>
        </Form> : code && email ? <>
            <p>We've updated your password. Click on the link below to be redirected on the login page.</p>

            <Button variant='primary' className='mt-4' onClick={() => handleNavigate('/login')}>
                Login now
            </Button>
        </> : <>
            <p>We've sent you an email to complete the password reset. Please follow the instructions from there to reset your password.</p>
        </>

        const headerDOM = !(code || email) ? 'Reset Password' : !isSubmitted && code && email ? 'Update Password' : 'Password Updated';

        return <Col xs={12} md={8} xl={6} className='mx-auto'>
            <h1 className='mb-5'>{headerDOM}</h1>
            {formDOM}
        </Col>
    }, [resetPasswordDOM, isButtonDisabled, fieldData, isSubmitted, userContext, globalError, code, email]);

    return <LandingPageLayout classNames='landingPage login' onlyLogo={false} pageTitle='Reset Password'>
        <Container>
            <Row>
                {elementsDOM}
            </Row>
        </Container>
    </LandingPageLayout>
};

export default ForgotPassword;